import { AuditTrailReportRepoImpl } from "domain/repository/AuditTrailReport/AuditTrailReportRepoImpl";
import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { RemoteFileInfoRepoImpl } from "domain/repository/Common/RemoteFileInfoRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { useAuditTrailReportTracked } from "presentation/store/AuditTrailReport/AuditTrailReportProvider";
import { AuditTrailReportVM } from "presentation/viewModel/AuditTrailReport/AuditTrailReportVM";
import { useMemo } from "react";

export const useAuditTrailReportVM = () => {
    const [, setAuditTrailReportState] = useAuditTrailReportTracked();
    const auditTrailReportVM = useMemo(() =>
        AuditTrailReportVM({
            dispatch: [setAuditTrailReportState],
            auditTrailReportRepo: AuditTrailReportRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            printQueueRepo: PrintQueueRepoImpl(),
            remoteFileInfoRepo: RemoteFileInfoRepoImpl(),
        }), [setAuditTrailReportState])

    return auditTrailReportVM
}