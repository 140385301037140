import { AuditTrailReportEntity } from "domain/entity/AuditTrailReport/AuditTrailReportEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import reportAxiosInstance from "../axios/reportAxiosInstance";
import { AuditTrailReportRepository } from "./AuditTrailReportRepo";

export const AuditTrailReportRepoImpl = (): AuditTrailReportRepository => {


    const initAuditTrailReportList = async (): Promise<string[]> => {
        const searchUrl = "/v1/initAuditTrailReportList"
        return axiosGetData(reportAxiosInstance, `${searchUrl}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const exportReport = async (entity: AuditTrailReportEntity): Promise<ResponseEntity> => {
        const exportUrl = "/v1/auditTrailExport"
        return await axiosPostData(reportAxiosInstance, `${exportUrl}`, entity);
    }

    const print = async (entity: AuditTrailReportEntity): Promise<ResponseEntity> => {
        const printUrl = "/v1/auditTrailPrint"
        return await axiosPostData(reportAxiosInstance, `${printUrl}`, entity);
    }

    const download = async (entity: AuditTrailReportEntity): Promise<ResponseEntity> => {
        const downloadUrl = "/v1/auditTrailDownload"
        return await axiosPostData(reportAxiosInstance, `${downloadUrl}`, entity);
    }
    const view = async (entity: AuditTrailReportEntity): Promise<ResponseEntity> => {
        const viewUrl = "/v1/auditTrailView"
        return await axiosPostData(reportAxiosInstance, `${viewUrl}`, entity);
    }

    return {
        initAuditTrailReportList: initAuditTrailReportList,
        exportReport: exportReport,
        print: print,
        view: view,
        download: download,
    }
}
