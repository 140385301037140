import { AuditTrailReportConstant } from "presentation/constant/AuditTrailReport/AuditTrailReportConstant";
import { billingTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useAuditTrailReportVM } from "presentation/hook/AuditTrailReport/useAuditTrailReportVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useAuditTrailReportTracked } from "presentation/store/AuditTrailReport/AuditTrailReportProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const AuditTrailReportPanel = () => {
    const AUDIT_TRAIL_REPORT_CONSTANT = AuditTrailReportConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [auditTrailReportState] = useAuditTrailReportTracked();
    const auditTrailReportVM = useAuditTrailReportVM();
    const { currentReport, reportIdTypeMap } = auditTrailReportState;
    const messageBarVM = useMessageBarVM();

    useEffect(() => {
        const refreshEnableField = async () => {
            (currentReport.reportType) && auditTrailReportVM.onReportTypeChanged(currentReport.reportType);
        }
        refreshEnableField();
    }, [auditTrailReportVM, currentReport.reportType])

    const isDocDateShowOptional = useCallback(() => {
        if (currentReport.reportType === "Credit Invoices" || currentReport.reportType === "Manual Invoice") return false;
        return true;
    }, [currentReport.reportType]);

    const isAdjDateShowOptional = useCallback(() => {
        if (currentReport.reportType === "Manual Adjustment of System Charge") return false;
        return true;
    }, [currentReport.reportType]);


    const checkFieldMandatory = useCallback(() => {
        if (currentReport.reportType === "Manual Adjustment of System Charge") {
            if (!currentReport.adjustmentDateFrom || !currentReport.adjustmentDateTo) {
                messageBarVM.showError("Adjustment Date (From) and Adjustment Date (To) is mandatory.");
                return true;
            }
            if (currentReport.adjustmentDateFrom > currentReport.adjustmentDateTo) {
                messageBarVM.showError("Adjustment Date (To) should be later than or equals to Adjustment Date (From).");
                return true;
            }
        }
        if ((currentReport.reportType === "Credit Invoices" || currentReport.reportType === "Manual Invoice")) {
            if (!currentReport.docDateFrom || !currentReport.docDateTo) {
                messageBarVM.showError("Doc Date (From) and Doc Date (To) is mandatory.");
                return true;
            }
            if (currentReport.docDateFrom > currentReport.docDateTo) {
                messageBarVM.showError("Doc Date (To) should be later than or equals to Doc Date (From).");
                return true;
            }
        }
        if (currentReport.transactionDateFrom && currentReport.transactionDateTo && currentReport.transactionDateFrom > currentReport.transactionDateTo) {
            messageBarVM.showError("Transaction Date (To) should be later than or equals to Transaction Date (From).");
            return true;
        }
        return false;
    }, [currentReport.adjustmentDateFrom, currentReport.adjustmentDateTo, currentReport.docDateFrom, currentReport.docDateTo, currentReport.reportType, currentReport.transactionDateFrom, currentReport.transactionDateTo, messageBarVM]);

    const handleReset = useCallback(() => {
        auditTrailReportVM.onResetClick();
    }, [auditTrailReportVM]);

    const handlePrint = useCallback(() => {
        if (checkFieldMandatory()) return;
        setIsLoading(true);
        auditTrailReportVM.onPrintClick(reportIdTypeMap, currentReport).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Report is sent to printers successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Report print failed");
            }
        });
    }, [auditTrailReportVM, checkFieldMandatory, currentReport, messageBarVM, reportIdTypeMap]);

    const handleExportClick = useCallback((exportType: string) => {
        if (checkFieldMandatory()) return;
        setIsLoading(true);
        auditTrailReportVM.onExportClick(exportType, reportIdTypeMap, currentReport).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Export successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Export failure.");
            }
        });
    }, [auditTrailReportVM, checkFieldMandatory, currentReport, messageBarVM, reportIdTypeMap]);

    const handleDownloadClick = useCallback((exportType: string) => {
        if (checkFieldMandatory()) return;
        setIsLoading(true);
        auditTrailReportVM.onDownloadClick(reportIdTypeMap, currentReport, exportType).then(data => {
            if (data === "success") {
                messageBarVM.showSuccess("Download successfully.");
                setIsLoading(false);
            } else {
                messageBarVM.showError(data);
                setIsLoading(false);
            }
        });
    }, [auditTrailReportVM, checkFieldMandatory, currentReport, messageBarVM, reportIdTypeMap]);

    const handleView = useCallback(() => {
        if (checkFieldMandatory()) return;
        setIsLoading(true);
        auditTrailReportVM.onViewClick(reportIdTypeMap, currentReport).then(data => {
            setIsLoading(false);
        });
    }, [auditTrailReportVM, checkFieldMandatory, currentReport, reportIdTypeMap]);

    const isManAdj = useCallback(() => {
        if (currentReport.reportType === "Manual Adjustment of System Charge") return true;
        return false;
    }, [currentReport.reportType]);

    const memoExportMenu = useMemo(() => {
        return [
            {
                title: AuditTrailReportConstant.Title.PDF,
                disabled: false,
                command: () => {
                    handleExportClick(AuditTrailReportConstant.Title.PDF);
                }
            },
            {
                title: AuditTrailReportConstant.Title.CSV,
                disabled: false,
                command: () => {
                    handleExportClick(AuditTrailReportConstant.Title.CSV);
                }
            },
            {
                title: AuditTrailReportConstant.Title.RTF,
                disabled: false,
                command: () => {
                    handleExportClick(AuditTrailReportConstant.Title.RTF);
                }
            }
        ]
    }, [handleExportClick])

    const memoDownloadMenu = useMemo(() => {
        return [
            {
                title: AuditTrailReportConstant.Title.CSV,
                disabled: false,
                command: () => {
                    handleDownloadClick(AuditTrailReportConstant.Title.CSV);
                }
            },
            {
                title: AuditTrailReportConstant.Title.RTF,
                disabled: false,
                command: () => {
                    handleDownloadClick(AuditTrailReportConstant.Title.RTF);
                }
            }
        ]
    }, [handleDownloadClick])

    const memoReportType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px", marginTop: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.reportType ?? ''}
                fieldValue={currentReport.reportType}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.REPORT_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reportType'}
                maxLength={60}
                options={auditTrailReportState.dynamicOptions.reportTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.REPORT_TYPE, auditTrailReportState.dynamicOptions.reportTypeDropdownOptions, auditTrailReportVM, currentReport.reportType])

    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.printer ?? ''}
                fieldValue={currentReport.printer}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.PRINTER}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printer'}
                maxLength={60}
                options={auditTrailReportState.dynamicOptions.printQueueDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.PRINTER, auditTrailReportState.dynamicOptions.printQueueDropdownOptions, auditTrailReportVM, currentReport.printer])

    const memoFileLocation = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.fileLocation ?? ''}
                fieldValue={currentReport.fileLocation}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.FILE_LOCATION}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fileLocation'}
                maxLength={60}
                options={auditTrailReportState.dynamicOptions.fileLocationDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.FILE_LOCATION, auditTrailReportState.dynamicOptions.fileLocationDropdownOptions, auditTrailReportVM, currentReport.fileLocation])

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.companyCode ?? ''}
                fieldValue={currentReport?.companyCode}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.COMPANY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCode'}
                maxLength={60}
                options={auditTrailReportState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.COMPANY_CODE, auditTrailReportState.dynamicOptions.companyCodeDropdownOptions, auditTrailReportVM, currentReport?.companyCode])

    const memoBillingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.billingType ?? ''}
                fieldValue={currentReport?.billingType}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.BILLING_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billingType'}
                maxLength={60}
                options={billingTypeDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.BILLING_TYPE, auditTrailReportVM, currentReport?.billingType])

    const memoProposalNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.proposalNo ?? ''}
                fieldValue={currentReport?.proposalNo}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.PROPOSAL_NO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalNo'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.PROPOSAL_NO, auditTrailReportVM, currentReport?.proposalNo])

    const memoDocNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.docNoFrom ?? ''}
                fieldValue={currentReport?.docNoFrom}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.DOC_NO_FROM}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'docNoFrom'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.DOC_NO_FROM, auditTrailReportVM, currentReport?.docNoFrom])

    const memoDocNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.docNoTo ?? ''}
                fieldValue={currentReport?.docNoTo}
                fieldLabel={AUDIT_TRAIL_REPORT_CONSTANT.DOC_NO_TO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'docNoTo'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    auditTrailReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.DOC_NO_TO, auditTrailReportVM, currentReport?.docNoTo])

    const memoDocDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={isDocDateShowOptional()}
                label={AUDIT_TRAIL_REPORT_CONSTANT.DOC_DATE_FROM}
                width="300px"
                date={currentReport?.docDateFrom}
                fieldName="docDateFrom"
                errorMessage={""}
                onDateChange={auditTrailReportVM.onHeaderFieldChange} />
        </div>
        , [isDocDateShowOptional, AUDIT_TRAIL_REPORT_CONSTANT.DOC_DATE_FROM, currentReport?.docDateFrom, auditTrailReportVM.onHeaderFieldChange])

    const memoDocDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={isDocDateShowOptional()}
                label={AUDIT_TRAIL_REPORT_CONSTANT.DOC_DATE_TO}
                width="300px"
                date={currentReport?.docDateTo}
                fieldName="docDateTo"
                errorMessage={""}
                onDateChange={auditTrailReportVM.onHeaderFieldChange} />
        </div>
        , [isDocDateShowOptional, AUDIT_TRAIL_REPORT_CONSTANT.DOC_DATE_TO, currentReport?.docDateTo, auditTrailReportVM.onHeaderFieldChange])

    const memoTransactionDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DateTimePickerComponent
                disabled={false}
                optional={true}
                label={AUDIT_TRAIL_REPORT_CONSTANT.TRANS_DATE_FROM}
                width="300px"
                date={currentReport?.transactionDateFrom}
                fieldName="transactionDateFrom"
                errorMessage={""}
                onDateChange={auditTrailReportVM.onHeaderFieldChange} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.TRANS_DATE_FROM, currentReport?.transactionDateFrom, auditTrailReportVM.onHeaderFieldChange])

    const memoTransactionDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DateTimePickerComponent
                disabled={false}
                optional={true}
                label={AUDIT_TRAIL_REPORT_CONSTANT.TRANS_DATE_TO}
                width="300px"
                date={currentReport?.transactionDateTo}
                fieldName="transactionDateTo"
                errorMessage={""}
                onDateChange={auditTrailReportVM.onHeaderFieldChange} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.TRANS_DATE_TO, currentReport?.transactionDateTo, auditTrailReportVM.onHeaderFieldChange])

    const memoAdjustmentDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={AUDIT_TRAIL_REPORT_CONSTANT.ADJ_DATE_FROM}
                width="300px"
                optional={isAdjDateShowOptional()}
                date={currentReport?.adjustmentDateFrom}
                fieldName="adjustmentDateFrom"
                errorMessage={""}
                onDateChange={auditTrailReportVM.onHeaderFieldChange} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.ADJ_DATE_FROM, isAdjDateShowOptional, currentReport?.adjustmentDateFrom, auditTrailReportVM.onHeaderFieldChange])

    const memoAdjustmentDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={AUDIT_TRAIL_REPORT_CONSTANT.ADJ_DATE_TO}
                width="300px"
                optional={isAdjDateShowOptional()}
                date={currentReport?.adjustmentDateTo}
                fieldName="adjustmentDateTo"
                errorMessage={""}
                onDateChange={auditTrailReportVM.onHeaderFieldChange} />
        </div>
        , [AUDIT_TRAIL_REPORT_CONSTANT.ADJ_DATE_TO, isAdjDateShowOptional, currentReport?.adjustmentDateTo, auditTrailReportVM.onHeaderFieldChange])
    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{AuditTrailReportConstant.Title.TITLE}</HeaderTitle>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            <StyledAction className="tm-animated-wrapper">
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CLEAR} size={"Small"} theme={"Secondary"} disabled={false} onClick={handleReset} />
                <div className="add-seperator" />
                {!isManAdj() && <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={"Small"} theme={"Secondary"} disabled={false} onClick={handlePrint} />}
                <OverflowMenuButton disabled={false} buttonLabel={WorkspaceConstant.Common.BUTTON_EXPORT_AS} navigationList={memoExportMenu} />
                {isManAdj() && <OverflowMenuButton disabled={false} buttonLabel={WorkspaceConstant.Common.BUTTON_DOWNLOAD_AS} navigationList={memoDownloadMenu} />}
                <HPHButton label={WorkspaceConstant.Common.BUTTON_VIEW} size={"Small"} theme={"Primary"} disabled={false} onClick={handleView} />
            </StyledAction>
        </Sidebarheader>

        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoReportType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoPrinter}
                            {memoFileLocation}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {auditTrailReportState.enabledField.docDate ? memoDocDateFrom : null}
                            {auditTrailReportState.enabledField.docDate ? memoDocDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {auditTrailReportState.enabledField.docNo ? memoDocNoFrom : null}
                            {auditTrailReportState.enabledField.docNo ? memoDocNoTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {auditTrailReportState.enabledField.transactionDate ? memoTransactionDateFrom : null}
                            {auditTrailReportState.enabledField.transactionDate ? memoTransactionDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {auditTrailReportState.enabledField.adjustmentDate ? memoAdjustmentDateFrom : null}
                            {auditTrailReportState.enabledField.adjustmentDate ? memoAdjustmentDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {auditTrailReportState.enabledField.companyCode ? memoCompanyCode : null}
                            {auditTrailReportState.enabledField.billingType ? memoBillingType : null}
                            {auditTrailReportState.enabledField.proposalNo ? memoProposalNo : null}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        </StyledSidebar >
    </>
}

export default memo(AuditTrailReportPanel);
