import { AuditTrailEnabledField, DEFAULT_ENABLED_AUDIT_TRAIL_FIELD } from "domain/entity/AuditTrailReport/AuditTrailEnabledField";
import { DEFAULT_ENABLED_FINANCIAL_REPORT_FIELD, FinancialReportEnabledField } from "domain/entity/FinancialReport/FinancialReportEnabledField";
import { DEFAULT_ENABLED_GENERAL_REPORT_FIELD, GeneralReportEnabledField } from "domain/entity/GeneralReport/GeneralReportEnabledField";

export const auditTrailReportEnabledField = (reportType: string): AuditTrailEnabledField => {
    let enabledField = { ...DEFAULT_ENABLED_AUDIT_TRAIL_FIELD };
    switch (reportType) {
        case "All Invoice with Invoice Line Details":
            enabledField = {
                ...enabledField,
                docNo: true,
                docDate: true,
            }
            break;
        case "Audit Trail for Company":
            enabledField = {
                ...enabledField,
                companyCode: true,
                transactionDate: true,
            }
            break;
        case "Audit Trail for Customer":
            enabledField = {
                ...enabledField,
                companyCode: true,
                transactionDate: true,
            }
            break;
        case "Audit Trail for Proposal":
            enabledField = {
                ...enabledField,
                companyCode: true,
                transactionDate: true,
                proposalNo: true,
            }
            break;
        case "Credit Invoices":
            enabledField = {
                ...enabledField,
                docDate: true,
            }
            break;
        case "Manual Adjustment of System Charge":
            enabledField = {
                ...enabledField,
                adjustmentDate: true,
                billingType: true,
            }
            break;
        case "Manual Invoice":
            enabledField = {
                ...enabledField,
                docDate: true,
            }
            break;
        default:
            break;
    }
    return enabledField;
}

export const generalReportEnabledField = (reportType: string): GeneralReportEnabledField => {
    let enabledField = { ...DEFAULT_ENABLED_GENERAL_REPORT_FIELD };
    switch (reportType) {
        case "Company Master Report":
            enabledField = {
                ...enabledField,
                companyCode: true,
                activeInd: true,
            }
            break;
        case "Credit Note Summary":
            enabledField = {
                ...enabledField,
                companyCode: true,
                masterCompanyCode: true,
                customerCode: true,
                opsDate: true,
                docDate: true,
            }
            break;
        case "Criteria Checklist":
            enabledField = {
                ...enabledField,
                criteriaType: true,
                effectiveDate: true,
                expiryDate: true,
            }
            break;
        case "Daily Invoice Summary":
            enabledField = {
                ...enabledField,
                customerCode: true,
                docDate: true,
            }
            break;
        case "Dayend Report":
            enabledField = {
                ...enabledField,
                customerCode: true,
                glDate: true,
                docDate: true,
                opsDate: true,
            }
            break;
        case "Empty Container Summary Report":
            enabledField = {
                ...enabledField,
                chargeOnCompany: true,
                opsDate: true,
            }
            break;
        case "Empty Pool Container List Report":
            enabledField = {
                ...enabledField,
                yearInput: true,
                month: true,
                chargeOnCompany: true,
            }
            break;
        case "Exception Report":
            enabledField = {
                ...enabledField,
                exceptionType: true,
            }
            break;
        case "Invoice Register":
            enabledField = {
                ...enabledField,
                ediView: true,
                companyCode: true,
                docNo: true,
                docDate: true,
                chargeType: true,
                masterCompanyCode: true,
            }
            break;
        case "Invoice Summary (Non Operation) Report":
            enabledField = {
                ...enabledField,
                glDate: true,
                docDate: true,
            }
            break;
        case "Invoice Summary (Operation) Report":
            enabledField = {
                ...enabledField,
                glDate: true,
                docDate: true,
            }
            break;
        case "Manual/Adjustment Credit Notes Summary":
            enabledField = {
                ...enabledField,
                glDate: true,
                docDate: true,
            }
            break;
        case "Monthly Invoice Summary":
            enabledField = {
                ...enabledField,
                yearMonth: true,
            }
            break;
        case "Monthly Outstanding Turnover Summary":
            enabledField = {
                ...enabledField,
                yearMonth: true,
            }
            break;
        case "Monthly Revenue Report":
            enabledField = {
                ...enabledField,
                fromYearMonth: true,
                monthlyRevenueCheckBox: true,
            }
            break;
        case "Monthly Turnover Check":
            enabledField = {
                ...enabledField,
                yearMonth: true,
            }
            break;
        case "Outstanding Turnover By Clerk":
            enabledField = {
                ...enabledField,
                billingClerk: true,
                date: true,
            }
            break;
        case "Outstanding Turnover Summary":
            enabledField = {
                ...enabledField,
                date: true,
            }
            break;
        case "Proposal Master Report":
            enabledField = {
                ...enabledField,
                proposalType: true,
                companyCode: true,
                version: true,
                proposalStatus: true,
                effectiveDate: true,
                proposalNo: true,
            }
            break;
        case "Summary of Customer Monthly Balance":
            enabledField = {
                ...enabledField,
                docDate: true,
                glDate: true,
            }
            break;
        case "T3 Invoice Revenue Report":
            enabledField = {
                ...enabledField,
                docDate: true,
                docNo: true,
                glDate: true,
                customerCode: true,
            }
            break;
        case "Tariff Adjustment Report":
            enabledField = {
                ...enabledField,
                proposalFromTo: true,
            }
            break;
        case "Tariff item Mapping List":
            enabledField = {
                ...enabledField,
                tariff: true,
                activeInd: true,
            }
            break;
        case "Tax Report":
            enabledField = {
                ...enabledField,
                docDate: true,
            }
            break;
        default:
            break;
    }
    return enabledField;
}

export const financialReportEnabledField = (reportType: string): FinancialReportEnabledField => {
    let enabledField = { ...DEFAULT_ENABLED_FINANCIAL_REPORT_FIELD };
    switch (reportType) {
        case "Billing By Client and Concept":
            enabledField = {
                ...enabledField,
                docDate: true,
                glDate: true,
            }
            break;
        case "Billing Comparative":
            enabledField = {
                ...enabledField,
                docDate: true,
                glDate: true,
            }
            break;
        case "Comparative between TEUs":
            enabledField = {
                ...enabledField,
                p1OperationDate: true,
                p2OperationDate: true,
            }
            break;
        case "Movement List":
            enabledField = {
                ...enabledField,
                soa: true,
                vslOperationDate: true,
            }
            break;
        case "Movements Per Line":
            enabledField = {
                ...enabledField,
                vslOperationDate: true,
            }
            break;
        case "Movements Per Ship Owner":
            enabledField = {
                ...enabledField,
                vslOperationDate: true,
            }
            break;
        case "Output Statistics":
            enabledField = {
                ...enabledField,
                etd: true,
            }
            break;
        case "Price Changes List":
            enabledField = {
                ...enabledField,
                chargeOnCompany: true,
                effectiveDate: true,
            }
            break;
        case "Rail Movement":
            enabledField = {
                ...enabledField,
                operationDate: true,
            }
            break;
        case "Revenue By Tariff And Customer":
            enabledField = {
                ...enabledField,
                billToCompany: true,
                yearMonth: true,
                printer: true,
            }
            break;
        case "TEUs Billed":
            enabledField = {
                ...enabledField,
                docDate: true,
                glDate: true,
            }
            break;
        case "Vessel movements report":
            enabledField = {
                ...enabledField,
                vslOperationDate: true,
            }
            break;
        default:
            break;
    }
    return enabledField;
}