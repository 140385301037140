export interface AuditTrailEnabledField {
    companyCode: boolean,
    billingType: boolean,
    docNo: boolean,
    docDate: boolean,
    transactionDate: boolean,
    proposalNo: boolean,
    adjustmentDate: boolean,

}

export const DEFAULT_ENABLED_AUDIT_TRAIL_FIELD: AuditTrailEnabledField = {
    companyCode: false,
    billingType: false,
    docNo: false,
    docDate: false,
    transactionDate: false,
    proposalNo: false,
    adjustmentDate: false
}