
export const AuditTrailReportConstant = {
    Table: {
        REPORT_TYPE: "Report",
        PRINTER: "Printer",
        FILE_LOCATION: "File Location",
        BILLING_TYPE: "Billing Type",
        COMPANY_CODE: "Company Code",
        DOC_DATE_FROM: "Doc Date (From)",
        DOC_DATE_TO: "Doc Date (To)",
        DOC_NO_FROM: "Doc No. (From)",
        DOC_NO_TO: "Doc No. (To)",
        TRANS_DATE_FROM: "Transaction Date/Time (From)",
        TRANS_DATE_TO: "Transaction Date/Time (To)",
        PROPOSAL_NO: "Proposal No.",
        ADJ_DATE_FROM: "Adjustment Date (From)",
        ADJ_DATE_TO: "Adjustment Date (To)",
        EXPORT_TYPE: "Export Type",
    },
    Title: {
        TITLE: "Audit Trail Report",
        PDF: "PDF",
        CSV: "CSV",
        RTF: "RTF",
    },
}
