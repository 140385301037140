export interface AuditTrailReportEntity {

    reportType: string,
    printer: string,
    fileLocation: string,
    companyCode?: string | null,
    billingType?: string | null,
    docNoFrom?: string | null,
    docNoTo?: string | null,
    docDateFrom?: Date | null,
    docDateTo?: Date | null,
    transactionDateFrom?: Date | null,
    transactionDateTo?: Date | null,
    proposalNo?: string | null,
    adjustmentDateFrom?: Date | null,
    adjustmentDateTo?: Date | null,
    exportType?: string | null,


    [key: string]: string | boolean | Date | number | null | Object | undefined
}

export const EMPTY_AUDIT_TRAIL_REPORT_ENTITY: AuditTrailReportEntity = {
    reportType: "",
    printer: "",
    fileLocation: "",
    companyCode: null,
    billingType: null,
    docDateFrom: null,
    docDateTo: null,
    docNoFrom: null,
    docNoTo: null,
    transactionDateFrom: null,
    transactionDateTo: null,
    proposalNo: null,
    adjustmentDateFrom: null,
    adjustmentDateTo: null,
    exportType: null,

}
