import { AuditTrailEnabledField, DEFAULT_ENABLED_AUDIT_TRAIL_FIELD } from "domain/entity/AuditTrailReport/AuditTrailEnabledField";
import { AuditTrailReportEntity, EMPTY_AUDIT_TRAIL_REPORT_ENTITY } from "domain/entity/AuditTrailReport/AuditTrailReportEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface AuditTrailReportDropdownOptions {
    reportTypeDropdownOptions: DropdownProps[],
    printQueueDropdownOptions: DropdownProps[],
    fileLocationDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
}
export interface AuditTrailReportViewChangeState extends BaseViewChangeSate {
}

export interface AuditTrailReportModel {
    isLoading: boolean,
    reportIdList: string[],
    reportTypeList: string[],
    printQueueIdMap: { [key: string]: number },
    reportIdTypeMap: { [key: string]: string },
    dynamicOptions: AuditTrailReportDropdownOptions,
    currentReport: AuditTrailReportEntity,
    enabledField: AuditTrailEnabledField,

}

export const EMPTY_AUDIT_TRAIL_REPORT_MODEL: AuditTrailReportModel = {
    isLoading: false,
    dynamicOptions: {
        reportTypeDropdownOptions: [],
        printQueueDropdownOptions: [],
        fileLocationDropdownOptions: [],
        companyCodeDropdownOptions: []
    },
    printQueueIdMap: {},
    reportTypeList: [],
    currentReport: { ...EMPTY_AUDIT_TRAIL_REPORT_ENTITY },
    enabledField: { ...DEFAULT_ENABLED_AUDIT_TRAIL_FIELD },
    reportIdTypeMap: {},
    reportIdList: [],
}