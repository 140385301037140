import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { AuditTrailReportProvider } from "presentation/store/AuditTrailReport/AuditTrailReportProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import AuditTrailReportMaintenance from "presentation/view/section/AuditTrailReport/AuditTrailReportMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const AuditTrailReportContMain = () => {
    return <ANAInfoWrapper permission={Permission.AUDIT_TRAIL_REPORT_MAINTENANCE}>
        <MessageBarWrapper>
            <AuditTrailReportProvider>
                <GridStyles/>
                <AuditTrailReportMaintenance/>
            </AuditTrailReportProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default AuditTrailReportContMain;